<template>
<the-header></the-header>
<div class="container">
    <b class="float" @click="formToggle">
        <router-link to="" >
            <h2 class="fa fa-plus my-float text-white"></h2>
        </router-link>
    </b>
    <div class="row">
        <search-action heading="Wokers" @searchlist="getlist"></search-action>
    </div>
    <transition name="msg" mode="out-in">
    <strong class="text-success">{{msg}}</strong>
    </transition>
   <add-worker v-if="formVisible" @close="formToggle" @load="getlist" :update="UpdateData" @msg="showMessgae"></add-worker>
    <div class="py-2 table_div">
        <table class="table table-striped border">
        <thead>
            <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Email</th>
            <th scope="col">Password</th>
            <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="getWorker.length==0">
                <td colspan="5" class="text-center">
                No Worker Yet. <b @click="fromToggle">Join A Worker</b></td></tr>
                
           <tr v-else v-for="data in getWorker.workers" :key="data.int_user_id">
            <th scope="row">{{data.int_user_id}}</th>
            <td>{{data.txt_name}}</td>
            <td>{{data.txt_telephone}}</td>
            <td>{{data.txt_email}}</td>
            <td>{{data.txt_password}}</td>
            <td><base-button mode="icon" @click="updateWorker(data.int_user_id)"><strong class="fas fa-edit" ></strong></base-button>
            <label class="switch border">
                <input type="checkbox" :class="data.ysn_active" :checked="data.ysn_active==1" @click="DeleteWorker(data)">
                <span class="slider round"></span>
            </label>
            </td>
            </tr>
            <tr style="text-align:right" v-if="getWorker.length=!0">
                <td colspan="8" >
                    <ul class='pagination' >
                       <li class='page-item' :class="{active : item==getActive}" v-for="(item, index)  in getPagination" v-bind:key="index"><a href="#" class='page-link btn btn-sm' @click="pageredirect(item)" >{{getPaginationText[index]}}</a></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</div>
</template>
<script>
import AddWorker from './AddWorker.vue'
export default {
    components:{
        AddWorker,
    },
    data(){
        return{
            formVisible:false,
            UpdateData:null,
            msg:null,
            search:null,
        }
    },methods:{
        pageredirect(id){
            this.getlist(id)
        },
        getlist(pn=1,option=null){
            try{
                this.$store.dispatch('worker/getWorker',{page_no:pn,option:option});
            }catch(error){
               console.log('error'+error)
            }
        },
        updateWorker(id){
            this.formVisible=true;
            this.UpdateData=id;
        },
        formToggle(){
            this.UpdateData=null;
            this.formVisible=!this.formVisible
        },
        showMessgae(msg){
            this.msg=msg;
            setTimeout(()=>{
                this.msg=null;
            },3000);
        },
        DeleteWorker(data){
            var active;
            if(data.ysn_active==1){
                active=0;
            }else{
                active=1;
            }
                try {
                    this.$store.dispatch("worker/updateWorker", {
                        id:data.int_user_id,
                        name: data.txt_name,
                        phone: data.txt_telephone,
                        email: data.txt_email,
                        password: data.txt_password,
                        active : active,
                    });
                }catch (error) {
                    alert('worker error alert')
                    this.error = error;
                }
            
        },

    },
    computed:{
        getWorker(){
            //console.log(this.$store._state.data.worker.workers);
           // return this.$store.getters['worker/getworker'];
           return this.$store._state.data.worker;
        },
        getActive(){
            return this.$store.getters['worker/getActive'];
        },
        getPagination(){
            return this.$store.getters['worker/getpagination'];
        },
        getPaginationText(){
            return this.$store.getters['worker/getpagination_text']
        }
    },
    created(){
        this.getlist();
    }
}
</script>
<style  scoped>
 .switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--bluenavy);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>