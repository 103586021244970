<template>
  <base-dialog :show="!!error" title="An Error" @close="handleError"
    ><p>{{ error }}</p></base-dialog
  >
  <base-dialog fixed :show="isLoading" title="Processing...">
    <base-spinner></base-spinner>
  </base-dialog>
  <form class="p-5 border">
    <fieldset>
      <legend v-if="!update">Add Worker</legend>
      <legend v-else>Update Worker</legend>
      <div class="row">
        <input type="hidden" name="id" id="id" v-model.trim="id">
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="user_name"> User Name <span>*</span> </label>
          <input
            type="text"
            id="user_name"
            ref="user_name"
            name="user_name"
            class="form-control form-control-sm"
            :class="{ invalid: invalidName }"
            v-model.trim="user_name"
            @blur="validInput"
          />
          <span v-if="invalidName" :class="{ invalid: invalidName }"
            >Invalid Name</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="phone_number"
            >Phone Number<span>*</span></label
          >
          <input
            type="number"
            id="phone_number"
            ref="phone_number"
            name="phone_number"
            class="form-control form-control-sm"
            :class="{ invalid: invalidNumber }"
            v-model.number="phone_number"
            @blur="validNumber"
            @keyup="validNumber"
          />
          <span v-if="invalidNumber" :class="{ invalid: invalidNumber }"
            >Invalid Number</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="email">Email<span>*</span></label>
          <input
            type="email"
            id="email"
            ref="email"
            name="email"
            class="form-control form-control-sm"
            :class="{ invalid: invalidemail }"
            v-model.number="email"
            @blur="validInput"
          />
          <span v-if="invalidemail" :class="{ invalid: invalidemail }"
            >Invalid Email</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="password">Password<span>*</span></label>
          <input
            type="password"
            id="password"
            ref="password"
            name="password"
            class="form-control form-control-sm"
            autocomplete
            :class="{ invalid: invalidpassword }"
            v-model.trim="password"
            @blur="validInput"
          />
          <span v-if="invalidemail" :class="{ invalid: invalidpassword }"
            >Invalid Password</span
          >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-if="update">
          <!--<button class="btn btn-sm" @click="close">Close</button>
          <button class="btn btn-sm" @click.prevent="updateData">Save</button>-->
             
            <base-button  @click.prevent="updateData" mode="btn"> Update </base-button >
            <base-button @click="close" mode="btn"> Close</base-button >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-else>
          
          <base-button @click="close" mode="btn"> Close</base-button >
          <base-button  @click.prevent="saveData(true)" mode="btn"> Save & Close </base-button >
          <base-button  @click.prevent="saveData" mode="btn"> Save & New </base-button >
          <!--<button class="btn btn-sm" @click="close">Close</button>
          <button class="btn btn-sm" @click.prevent="saveData(true)">
            Save & Close
          </button>
          <button class="btn btn-sm" @click.prevent="saveData">
            Save & New
          </button>-->
        </div>
      </div>
    </fieldset>
  </form>
</template>
<script>
export default {
  emits: ["close", "load","msg"],
  props: ["update"],
  data() {
    return {
      id:null,
      user_name: null,
      phone_number: null,
      email: null,
      password: null,
      isLoading: false,
      error: null,

      invalidName: false,
      invalidNumber: false,
      invalidemail: false,
      invalidpassword: false,
      valid: null,
    };
  },
  methods: {
    async saveData(selfclose = false) {
      let valid = null;
      this.validInput();
      if (
        this.invalidName ||
        this.invalidemail ||
        this.invalidNumber ||
        this.invalidpassword
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("worker/addworker", {
            name: this.user_name,
            phone: this.phone_number,
            email: this.email,
            password: this.password,
          });
        } catch (error) {
          alert('worker error alert')
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          if (selfclose == true) {
            this.$emit("close");
          }
          this.$emit("msg","Worker Added Successfully")
          this.$emit("load");
          this.user_name = null;
          this.phone_number = null;
          this.email = null;
          this.password = null;
        }, 2000);
      }
    },
    getdata() {
      if (this.update) {
        this.$store.dispatch("worker/getsingleworker", { id: this.update });
        this.isLoading = true;
        setTimeout(() => {
          this.setData();
          this.isLoading=false;
        }, 2000);
      }
    },
    handleError() {
      this.error = null;
    },
    close() {
      this.$emit("close");
    },
    validInput() {
      if (!this.user_name) {
        this.invalidName = true;
        this.$refs.user_name.focus();
      } else if (this.$refs.phone_number.value.length < 10) {
        this.validNumber();
      } else if (!this.$refs.email.value.includes("@")) {
        this.invalidemail = true;
        this.$refs.email.focus();
      } else if (!this.password) {
        this.invalidpassword = true;
        this.$refs.password.focus();
      }
      if (this.user_name) {
        this.invalidName = false;
      }
      if (this.password) {
        this.invalidpassword = false;
      }
      if (this.$refs.email.value.includes("@")) {
        this.invalidemail = false;
      }
      if (
        (this.$refs.phone_number.value.length == 10 &&
          this.$refs.phone_number.value.startsWith("9")) ||
        this.$refs.phone_number.value.startsWith("7") ||
        this.$refs.phone_number.value.startsWith("6")
      ) {
        this.invalidNumber = false;
      }
    },
    validNumber() {
      if (
        this.$refs.phone_number.value.length > 10 ||
        this.$refs.phone_number.value.length < 10 ||
        this.$refs.phone_number.value.length == 10
      ) {
        if (this.$refs.phone_number.value.length == 10) {
          if (
            this.$refs.phone_number.value.startsWith("8") ||
            this.$refs.phone_number.value.startsWith("9") ||
            this.$refs.phone_number.value.startsWith("7") ||
            this.$refs.phone_number.value.startsWith("6")
          ) {
            this.invalidNumber = false;
          } else {
            this.invalidNumber = true;
            this.$refs.phone_number.focus();
          }
        } else {
          this.invalidNumber = true;
          this.$refs.phone_number.focus();
        }
      } else {
        this.invalidNumber = true;
        this.$refs.phone_number.focus();
      }
    },
    setData() {
      const Data = this.$store.getters["worker/singleworker"];
      this.id=Data[0].int_user_id;
      this.user_name= Data[0].txt_name;
      this.email = Data[0].txt_email;
      this.phone_number = Data[0].txt_telephone;
      this.password = Data[0].txt_password;
    },
    async updateData() {
      let valid = null;
      this.validInput();
      if (
        this.invalidName ||
        this.invalidemail ||
        this.invalidNumber ||
        this.invalidpassword
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("worker/updateWorker", {
            id:this.id,
            name: this.user_name,
            phone: this.phone_number,
            email: this.email,
            password: this.password,
            active : 1,
          });
        } catch (error) {
          alert('worker error alert')
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("close");
          this.$emit("load");
          this.$emit("msg","Worker Updated Successfully")
          this.user_name = null;
          this.phone_number = null;
          this.email = null;
          this.password = null;
          this.id=null;
        }, 2000);
      }
    },

  },
  created() {
    this.getdata();
  },
};
</script>
<style scoped>

</style>
